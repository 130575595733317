<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              v-if="canEditTeam"
              variant="primary"
              :disabled="isAddRecordActive"
              @click="openTeamModel()"
            >
              <span>{{ $t('ADD_TEAM') }} </span>
              <feather-icon icon="PlusCircleIcon" />
            </b-button>
            <b-alert
              v-if="allUser===1"
              variant="info"
              show
              class="ml-1"
            >
              <div class="alert-body">
                <span>{{ $t('EVERYONE') }}
                </span>
              </div>
            </b-alert>
            <b-alert
              variant="info"
              show
              class="ml-1"
            >
              <div class="alert-body">
                <span>{{ resolveGameType(competition.gametype) }}
                </span>
              </div>
            </b-alert>
          </b-col>

          <!-- Search Game-->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-dropdown
                v-show="userData.userRoleId===userRole.ADMIN"
                variant="link"
                no-caret
                class="chart-dropdown"
                toggle-class="p-0"
              >
                <template #button-content>
                  <feather-icon
                    size="17"
                    icon="MoreVerticalIcon"
                    class="text-body cursor-pointer"
                  />
                </template>
                <b-dropdown-item
                  v-model="allUser"
                  @click="onUser(0)"
                >
                  {{ $t('ONLY_COMPANY') }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-model="allUser"
                  @click="onUser(1)"
                >
                  {{ $t('EVERYONE') }}
                </b-dropdown-item>
              </b-dropdown>
              <v-select
                v-model="competition"
                :options="competitions"
                label="c_string_swe"
                :clearable="false"
                class="flex-grow-1 ml-50 mr-1"
                :placeholder="$t('SELECT_CONTENT')"
                @input="onChangeCompetition()"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card
      no-body
    >
      <b-row>
        <b-col
          v-for="(group,index) in groups"
          :key="index"
          lg="4"
          md="6"
        >
          <b-card class="card-app-design">

            <!-- design group -->
            <div class="design-group">
              <h6 class="section-label">
                {{ mapTeamRoleToVariant(group.groupcomplevel).description_2 }}
              </h6>
              <b-badge
                :key="index"
                :variant="changeVariant(index)"
                class="mr-1"
              >
                {{ group.groupname }}
              </b-badge>
              <feather-icon
                v-if="canEditTeam"
                icon="EditIcon"
                size="16"
                class="cursor-pointer  mr-1"
                @click="openTeamModel(group)"
              />
              <feather-icon
                v-if="group.users.length>0 && canEditTeam"
                icon="MailIcon"
                size="16"
                class="cursor-pointer mr-1"
                @click="showMessageEmailInvitation(group)"
              />
              <feather-icon
                v-if="canEditTeam"
                icon="Trash2Icon"
                size="16"
                class="cursor-pointer  mr-1"
                @click="onDeleteGroup(group)"
              />
            </div>
            <div class="design-group">
              <div>
                <h6 class="section-label">
                  {{ mapTeamRoleToVariant(group.groupcomplevel).description_1 }}
                  <feather-icon
                    v-if="canEditTeam"
                    v-show="!(group.users.length>=1 && competition.gametype===3 && group.groupcomplevel!='-2')"
                    icon="UserPlusIcon"
                    size="16"
                    class="cursor-pointer ml-1"
                    @click="openNewPlayerModel(group)"
                  />
                  <feather-icon
                    v-if="group.users.length>0 && canEditTeam"
                    icon="UserMinusIcon"
                    size="16"
                    class="cursor-pointer ml-1"
                    @click="openPlayerModel(group.users)"
                  />
                </h6>
              </div>
            </div>
            <b-badge
              v-for="(user,userIndex) in group.users"
              :key="userIndex"
              :variant="changeVariant(index)"
              class="mr-1"
            >
              {{ user.firstname + ' ' + user.lastname.charAt(0) }}
            </b-badge>
          </b-card>
        </b-col>
      </b-row>
      <team-model
        :fetch-group="fetchGroup"
        :competition="competition"
        :can-add-team="canAddTeam"
        :edit-group="editGroup"
      />
      <player-model
        :fetch-group="fetchGroup"
        :competition="competition"
        :users="users"
      />
      <new-player-model
        :fetch-group="fetchGroup"
        :competition="competition"
        :add-group="addGroup"
        :all-user="allUser"
      />
    </b-card>
    <b-card
      no-body
    ><team-view
      :group-users="groupUsers"
      :fetch-group="fetchGroup"
      :competition="competition"
      :can-edit-team="canEditTeam"
    />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton,
  VBTooltip, BRow, BCol, BBadge,
  BDropdown, BDropdownItem, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapActions, mapState } from 'vuex'
import { mixinList } from '@/constants/mixinValidations'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import constants from '@/constants/static.json'
import { canEditDeleteGame, mapTeamRoleToVariant } from '@/constants/utils'
import useJwt from '@/auth/jwt/useJwt'
import mixinAlert from '@/constants/mixinAlert'
import TeamModel from './TeamModel.vue'
import PlayerModel from '../team-configuration/PlayerModel.vue'
import NewPlayerModel from '../team-configuration/NewPlayerModel.vue'
import TeamView from '../team-view/TeamView.vue'
// eslint-disable-next-line import/named
// eslint-disable-next-line import/extensions, import/no-unresolved

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BButton,
    vSelect,
    TeamModel,
    PlayerModel,
    NewPlayerModel,
    TeamView,
    BDropdown,
    BDropdownItem,
    BAlert,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [mixinList, mixinDate],
  data() {
    return {
      competitions: [],
      canEditTeam: true,
      canAddTeam: true,
      allUser: 0,
      users: {},
      newUsers: {},
      userRole: constants.USER_ROLE,
      competition: useJwt.getContent(),
      userData: useJwt.getUser(),
      editGroup: {
        groupID: 0,
        groupcomplevel: 0,
        competitions: 0,
        companyID: 0,
        B: 0,
        G: 0,
        R: 0,
        groupname: '',
        competition_group: {},
      },
      emptyGroup: {
        groupID: 0,
        groupcomplevel: 0,
        competitions: 0,
        companyID: 0,
        B: 0,
        G: 0,
        R: 0,
        groupname: '',
        competition_group: {},
      },
      addGroup: {},
      groups: [],
      groupUsers: [],
      isAddRecordActive: true,
    }
  },
  computed: {
    ...mapState('game', ['allGames']),
  },
  mounted() {
    this.fetchAllGames().then(() => {
      this.competitions = this.allGames
      if (router.currentRoute.params.id) {
        this.competition = this.competitions.find(i => i.encrypt_id === router.currentRoute.params.id)
      }
      this.onChangeCompetition()
    })
  },
  methods: {
    ...mapActions('onboarding', ['fetchAllGame']),
    ...mapActions('game', ['fetchAllGames', 'deleteGroup', 'sendInvitationEmail']),
    fetchGroup(id) {
      this.fetchAllGame(id).then(response => {
        if (response) {
          this.groups = response.data.groups
          this.setGroupUsers(this.groups)
          this.resolvingTeamSize()
        }
      })
    },
    showMessageEmailInvitation(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.INVITATION_EMAIL_CONFIRMATION')}  ${data.groupname}  ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.sendInvitationEmail({ group: data, competition: this.competition, user: null }).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.PLAYER_INVITED'))
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        })
    },
    onUser(value) {
      this.allUser = value
    },
    setGroupUsers(data) {
      this.groupUsers = []
      data.forEach(group => {
        group.users.forEach(user => {
          const userData = { ...user }
          userData.groupcomplevel = group.groupcomplevel
          userData.groupID = group.groupID
          userData.groupname = group.groupname
          this.groupUsers.push(userData)
        })
      })
    },
    openTeamModel(data) {
      if (data) {
        this.editGroup = { ...data }
      } else {
        this.editGroup = { ...this.emptyGroup }
      }
      this.$bvModal.show('team-modal')
    },
    openPlayerModel(data) {
      if (data) {
        this.users = { ...data }
      }
      this.$bvModal.show('player-modal')
    },
    openNewPlayerModel(data) {
      if (data) {
        this.addGroup = { ...data }
        delete this.addGroup.users
      }
      this.$bvModal.show('new-player-modal')
    },
    onDeleteGroup(data) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.groupname}  group ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            const payLoad = { competitionID: data.competition_group.competitionID, groupID: data.competition_group.groupID, encrypt_id: this.competition.encrypt_id }
            this.deleteGroup(payLoad).then(response => {
              if (response) {
                this.fetchGroup(this.competition.encrypt_id)
                this.successMessage(this.$i18n.t('MESSAGE.TEAM_DELETED'))
              }
            }).catch(() => {
              this.errorMessage(this.$i18n.t('MESSAGE.TEAM_ATTACHED'))
            })
          }
        })
    },
    resolvingTeamSize() {
      this.canAddTeam = true
      const teams = this.groups.filter(i => i.groupcomplevel !== -2)
      if (teams) {
        if (teams.length >= 1 && this.competition.gametype === 2) {
          this.canAddTeam = false
        }
      }
    },
    onChangeCompetition() {
      if (this.competition) {
        useJwt.setContent(this.competition)
        this.canEditTeam = canEditDeleteGame(this.competition)
        this.fetchGroup(this.competition.encrypt_id)
        this.isAddRecordActive = false
      }
    },
  },
  setup() {
    const {
      successMessage, errorMessage, showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      showErrorMessage,
      mapTeamRoleToVariant,
    }
  },

}
</script>
